/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import {
  ButtonOrderGenerate,
  ButtonReturn,
} from "src/components/ButtonsCommons";
import Layout from "src/components/Layout";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useShoppingProcessType from "src/hooks/useShoppingProcessType";
import useShoppingProccessRate from "src/hooks/useShoppingProccessRate";
import useShoppingProccessStatus from "src/hooks/useShoppingProccessStatus";
import LOGO from "src/assets/images/Logo.svg";
import { format } from "date-fns";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";
import useParticipatingCompanies from "src/hooks/useParticipatingCompanies";
import { maskCnpj } from "src/functions/text";

const ShoppingProccessPdfDemandas = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    number,
    description,
    type,
    monthNumber,
    contactCompanyName,
    shoppingProcesSelect,
    status,
    buyerAdministratorValue,
    registerDate,
  } = useShoppingProcess();

  const { demands } = useShoppingProccessDemand();

  const { toPDF, targetRef } = usePDF({
    filename: `${number}.pdf`,
  });

  const { shoppingProcessType } = useShoppingProcessType();
  const { valorTaxaTotal } = useShoppingProccessRate();
  const { shoppingProccessStatus } = useShoppingProccessStatus();
  const { participatingCompanies } = useParticipatingCompanies();

  const buttons = [
    <ButtonReturn label="Voltar" onClick={() => router(-1)} />,
    <ButtonOrderGenerate label="Download" onClick={() => toPDF()} />,
  ];

  const borderStyle = {
    borderTop: "1px solid gray",
    borderBottom: "1px solid gray",
    borderRight: "1px solid gray",
    padding: "8px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const borderStyle2 = {
    borderTop: "1px solid gray",
    borderBottom: "1px solid gray",
    borderRight: "1px solid gray",
    padding: "8px",
    height: "100px",
    display: "flex",
    alignItems: "center",
    width: "150px",
  };

  return (
    <Layout buttons={buttons}>
      <div
        ref={targetRef}
        style={{
          padding: "30px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "2%",
          }}
        >
          <div>
            <img alt="logo" src={LOGO} />
          </div>
          <div>
            <strong>Usuário:</strong>
            <span>
              {`  ${
                JSON.parse(localStorage.getItem("user-logged") ?? "")?.login
              }  `}
            </span>
            <strong>Data impressão:</strong>
            <span>{`  ${format(new Date(), "dd/MM/yyyy HH:mm")}`}</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                flex: 3,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {`${translations[param]["number"]} ${translations[param]["procces"]}`}
              :
            </span>
            <span style={{ flex: 4 }}>{number}</span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                flex: 3,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {`${translations[param]["description"]} ${translations[param]["procces"]}`}
              :
            </span>
            <span style={{ flex: 4 }}>{description}</span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                flex: 3,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {translations[param]["management_company"]}:
            </span>
            <span style={{ flex: 4 }}>
              {buyerAdministratorValue?.label ?? ""}
            </span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                flex: 3,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {translations[param]["management_responsible"]}:
            </span>
            <span style={{ flex: 4 }}>{contactCompanyName}</span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", flex: 3 }}>
              <span
                style={{
                  flex: 1.2,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {translations[param]["initial_date"]}:
              </span>
              <span style={{ flex: 4 }}>
                {registerDate
                  ? format(new Date(registerDate), "dd/MM/yyyy")
                  : ""}
              </span>
            </div>

            <div style={{ display: "flex", alignItems: "center", flex: 4 }}>
              <span
                style={{
                  flex: 1,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {translations[param]["validity"]}:
              </span>
              <span style={{ flex: 6 }}>{`${monthNumber} ${
                monthNumber === "1"
                  ? translations[param]["month"]?.toLocaleLowerCase()
                  : translations[param]["months"]?.toLocaleLowerCase()
              }`}</span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", flex: 3 }}>
              <span
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                {translations[param]["value"]}:
              </span>
              <span style={{ flex: 4 }}>
                {shoppingProcesSelect?.totalValue?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>

            <div style={{ display: "flex", alignItems: "center", flex: 4 }}>
              <span
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                {translations[param]["rate"]}:
              </span>
              <span style={{ flex: 4 }}>{`${valorTaxaTotal}%`}</span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", flex: 3 }}>
              <span
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                {translations[param]["type"]}:
              </span>
              <span style={{ flex: 4 }}>
                {shoppingProcessType?.find((t) => t.id === type)?.description ??
                  ""}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center", flex: 4 }}>
              <span
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                {translations[param]["status"]}:
              </span>
              <span style={{ flex: 4 }}>
                {shoppingProccessStatus?.find((s) => s.id === status)
                  ?.description ?? ""}
              </span>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}></div>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center", marginTop: "4%" }}>
          <span
            style={{
              flex: 3,
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Compradores participantes:
          </span>
        </div>

        <div
          style={{
            paddingTop: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            <span
              style={{
                ...borderStyle,
                flex: "0 0 150px",
                borderLeft: "1px solid gray",
              }}
            >
              CNPJ
            </span>
            <span style={{ ...borderStyle, flex: 5 }}>EMPRESA</span>
            <span style={{ ...borderStyle, flex: 5 }}>CONTATO</span>
            <span style={{ ...borderStyle, flex: 5 }}>E-MAIL CONTATO</span>
            <span style={{ ...borderStyle, flex: 5 }}>DECLINOU ?</span>
          </div>
          {participatingCompanies.map((item: any) => (
            <div
              key={item.id}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  ...borderStyle2,
                  flex: "0 0 150px",
                  borderLeft: "1px solid gray",
                }}
              >
                {item.cnpj ? maskCnpj(item.cnpj) : "Não informado"}
              </span>
              <span style={{ ...borderStyle2, flex: 5 }}>
                {item.nomeFantasia}
              </span>
              <span style={{ ...borderStyle2, flex: 5 }}>
                {item.nomeResponsavelComprador}
              </span>
              <span style={{ ...borderStyle2, flex: 5 }}>
                {item.emailResponsavelComprador}
              </span>
              <span style={{ ...borderStyle2, flex: 5 }}>
                {item.declinouParticipacao ? "SIM" : "NÃO"}
              </span>
            </div>
          ))}
        </div>

        <div style={{ display: "flex", alignItems: "center", marginTop: "4%" }}>
          <span
            style={{
              flex: 3,
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {translations[param]["items"]}:
          </span>
        </div>

        {/* Items */}
        <div style={{ paddingTop: "10px", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              <span
                style={{
                  ...borderStyle,
                  flex: 1,
                  borderLeft: "1px solid gray",
                }}
              >
                CÓDIGO
              </span>
              <span style={{ ...borderStyle, flex: 3 }}>DESCRIÇÃO</span>
              <span style={{ ...borderStyle, flex: 1 }}>QUANTIDADE</span>
            </div>
            {demands?.map((item: any) => (
              <div
                key={item.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    ...borderStyle,
                    flex: 1,
                    borderLeft: "1px solid gray",
                  }}
                >
                  {item.produtoCodigo}
                </span>
                <span style={{ ...borderStyle, flex: 3 }}>
                  {item.produtoDescricao}
                </span>
                <span style={{ ...borderStyle, flex: 1 }}>
                  {item.quantidade}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ShoppingProccessPdfDemandas;
