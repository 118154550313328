/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useMemo } from "react";
import { toast } from "react-toastify";
import { iPage } from "src/interfaces/layout";
import ParticipatingCompaniesConsumer from "src/services/participatingCompanies";

interface iParticipatingCompaniesSelect {
  id?: string;
  cnpj?: string;
  processoComprasId?: string;
  empresasId?: string;
  empresasNome?: string;
  declinouParticipacao?: boolean;
  responsavelId?: string;
  responsavelNome?: string;
  email?: string;
}

const ParticipatingCompaniesContext = createContext<any>({} as any);

export function ParticipatingCompaniesProvider({
  children,
}: {
  children: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledField, setDisabledField] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogMail, setOpenDialogMail] = useState<boolean>(false);
  const [openDialogContacts, setOpenDialogContacts] = useState<boolean>(false);
  const [contactClickedToShowDialog, setContactClickedToShowDialog] =
    useState<boolean>(false);
  const [logSelect, setLogSelect] = useState("");
  const [participatingCompanies, setParticipatingCompanies] = useState<
    Array<any>
  >([]);

  const [participatingCompaniesForAdd, setParticipatingCompaniesForAdd] =
    useState<Array<any>>([]);

  const [participatingCompaniesSelect, setParticipatingCompaniesSelect] =
    useState<iParticipatingCompaniesSelect | null>(null);
  const [contact, setContact] = useState<any>(null);
  const [sendMails, setSendMails] = useState<any[]>([]);
  const [contactsSelected, setContactsSelected] = useState<any[]>([]);
  const [searchCompany, setSearchCompany] = useState<string>("");

  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [pageSendMails, setPageSendMails] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangePageSendMails = (_: unknown, newPage: number) => {
    setPageSendMails({ ...pageSendMails, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const handleChangeRowsPerPageSendMails = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSendMails({
      ...pageSendMails,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getCompanysParticipating = async (
    shoppingProccessId: string,
    rowsPerpage?: number
  ) => {
    setLoading(true);

    let currentPage = page;

    if (rowsPerpage) {
      currentPage = {
        ...page,
        page: 0,
        rowsPerPage: rowsPerpage,
      };
    }

    try {
      const response = await ParticipatingCompaniesConsumer.get(
        currentPage,
        shoppingProccessId
      );

      if (response.status !== 200) throw response;

      if (response.data.totalItems !== 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setParticipatingCompanies(response?.data?.items);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Erro ao buscar as emrpesas participantes!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (item: any | null) => {
    setParticipatingCompaniesSelect({
      ...item,
      id: item?.id ?? "",
      empresasNome: item?.nomeFantasia ?? "",
      label: item?.nomeFantasia ?? "",
      responsavelId: item?.responsavelCompradorId ?? "",
      responsavelNome: item?.nomeResponsavelComprador ?? "",
      email: item?.emailResponsavelComprador ?? "",
    });
    setContact({
      responsavelCompradorId: item?.responsavelCompradorId ?? "",
      responsavelCompradorNome: item?.nomeResponsavelComprador ?? "",
      label: item?.nomeResponsavelComprador ?? "",
      responsavelCompradorEmail: item?.emailResponsavelComprador ?? "",
    });
  };

  const handleNewSalve = async (shoppingProccessId: string) => {
    setLoading(true);

    try {
      let body: any = {
        items: [
          {
            processoComprasId: shoppingProccessId,
            empresasId: participatingCompaniesSelect?.empresasId,
            responsavelCompradorId: contact?.responsavelCompradorId,
            declinouParticipacao: false,
          },
        ],
      };

      if (participatingCompaniesForAdd?.length !== 0) {
        body = {
          items: participatingCompaniesForAdd,
        };
      }

      const response = await ParticipatingCompaniesConsumer.created(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      setOpenDialog(false);

      getCompanysParticipating(shoppingProccessId);

      setParticipatingCompaniesForAdd([]);
      handleSelected(null);

      toast.success("Participante cadastrado!");
    } catch (e) {
      toast.error("Erro ao cadastrar um participante!");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);

    try {
      const body: any = {
        id: participatingCompaniesSelect?.id,
        processoComprasId: participatingCompaniesSelect?.processoComprasId,
        empresasId: participatingCompaniesSelect?.empresasId,
        responsavelCompradorId: contact?.responsavelCompradorId,
        declinouParticipacao:
          participatingCompaniesSelect?.declinouParticipacao,
      };

      const response = await ParticipatingCompaniesConsumer.updated(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      const newParticipatingCompanies = participatingCompanies.filter(
        (el: any) => el.id !== participatingCompaniesSelect?.id
      );

      setParticipatingCompanies([
        {
          ...participatingCompaniesSelect,
          responsavelCompradorId: contact?.responsavelCompradorId,
          emailResponsavelComprador: contact?.responsavelCompradorEmail,
          nomeResponsavelComprador: contact?.responsavelCompradorNome,
        },
        ...newParticipatingCompanies,
      ]);

      handleSelected(null);
      toast.success("Participante alterado!");

      setOpenDialog(false);
    } catch (e) {
      toast.error("Erro ao alterar um participante!");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    setLoading(true);

    try {
      const response = await ParticipatingCompaniesConsumer.delete(id);

      if (response.status !== 200 && response.status !== 201) throw response;

      const newParticipatingCompanies = participatingCompanies.filter(
        (el: any) => el.id !== id
      );

      setParticipatingCompanies(newParticipatingCompanies);

      handleSelected(null);
      toast.success("Participante deletado!");
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? "Erro ao deletar um participante!"
      );
    } finally {
      setLoading(false);
    }
  };

  const getLog = async (shoppingProccessId: string, email: string) => {
    setLoading(true);

    try {
      const response = await ParticipatingCompaniesConsumer.getEmailsSend(
        shoppingProccessId,
        email
      );

      if (response.status !== 200 && response.status !== 201) throw response;

      if (response.data.totalItems !== 0) {
        setPageSendMails({
          ...pageSendMails,
          total: response.data.totalItems,
        });
      }

      setSendMails(response.data.items);
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? "Erro ao buscar os logs");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveNewConstacts = async (shoppingProccessId: string) => {
    setOpenDialogContacts(false);
    setLoading(true);

    try {
      const body = {
        pcParticipantesContatos: contactsSelected.map((item: any) => {
          return {
            processoComprasParticipantesId: item.processoComprasParticipantesId,
            contatoCompradorId: item.id,
            contatoPrincipal: true,
          };
        }),
      };

      const response =
        await ParticipatingCompaniesConsumer.pCParticipantesContatos(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      await getCompanysParticipating(shoppingProccessId);

      toast.success("Contatos salvos com sucesso!");
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "Erro ao cadastrar os contatos!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConstacts = async (
    id: string,
    shoppingProccessId: string
  ) => {
    setOpenDialogContacts(false);
    setLoading(true);

    try {
      const response =
        await ParticipatingCompaniesConsumer.deletePcParticipantesContatos(id);

      if (response.status !== 200 && response.status !== 201) throw response;

      await getCompanysParticipating(shoppingProccessId);

      toast.success("Contato deletado!");
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? "Erro ao deletar o contato!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      loading,
      setLoading,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      participatingCompanies,
      setParticipatingCompanies,
      participatingCompaniesSelect,
      setParticipatingCompaniesSelect,
      contact,
      setContact,
      getCompanysParticipating,
      handleSelected,
      openDialog,
      setOpenDialog,
      searchCompany,
      setSearchCompany,
      handleNewSalve,
      handleUpdate,
      handleDelete,
      participatingCompaniesForAdd,
      setParticipatingCompaniesForAdd,
      disabledField,
      setDisabledField,
      sendMails,
      setSendMails,
      getLog,
      openDialogMail,
      setOpenDialogMail,
      logSelect,
      setLogSelect,
      openDialogContacts,
      setOpenDialogContacts,
      contactClickedToShowDialog,
      setContactClickedToShowDialog,
      contactsSelected,
      setContactsSelected,
      handleSaveNewConstacts,
      handleDeleteConstacts,
      pageSendMails,
      setPageSendMails,
      handleChangePageSendMails,
      handleChangeRowsPerPageSendMails,
    };
  }, [
    page,
    loading,
    participatingCompanies,
    participatingCompaniesSelect,
    contact,
    openDialog,
    searchCompany,
    participatingCompaniesForAdd,
    disabledField,
    sendMails,
    openDialogMail,
    logSelect,
    openDialogContacts,
    contactClickedToShowDialog,
    contactsSelected,
    pageSendMails,
  ]);

  return (
    <ParticipatingCompaniesContext.Provider value={contextValue}>
      {children}
    </ParticipatingCompaniesContext.Provider>
  );
}

export default ParticipatingCompaniesContext;
