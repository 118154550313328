/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Checkbox,
  Typography,
  Divider,
  Collapse,
  Box,
} from "@mui/material";
import {
  Add,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { palette, theme } from "src/theme";
import useConsolidateDemand from "src/hooks/useConsolidateDemand";
import DialogComponent from "src/components/Dialog";
import SuggestedItemForm from "./SuggestedItemForm";
import useDemandCollectionForm from "src/hooks/useDemandCollectionForm";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import CircularLoading from "src/components/Loading";
import { useEffect } from "react";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";
import useProduct from "src/hooks/useProduct";
import Swal from "sweetalert2";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import useParticipatingCompanies from "src/hooks/useParticipatingCompanies";
import SelectedHospital from "./SelectedHospital";
import { toast } from "react-toastify";
import DialogPaymentCondition from "./DialogPaymentCondition";
import { maskCnpj } from "src/functions/text";

const ConsolidateDemand: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    openRow,
    openDialog,
    setOpenDialog,
    handleRowClick,
    itemVinculation,
    setItemVinculation,
    remove,
    generateCSV,
    openDialogSecondary,
    setOpenDialogSecondary,
    openDialogThree,
    setOpenDialogThree,
  } = useConsolidateDemand();

  const { get, loading, itemsSuggstionsCreated } = useDemandCollectionForm();

  const { shoppingProcesSelect } = useShoppingProcess();

  const {
    getDemands,
    demands,
    gmvInitialTotal,
    requestDemands,
    itemsToTrade,
    setItemsToTrade,
  } = useShoppingProccessDemand();

  const { participatingCompanies } = useParticipatingCompanies();

  const { setProductsToSearch } = useProduct();

  useEffect(() => {
    if (shoppingProcesSelect?.id) {
      get(shoppingProcesSelect.id, true);
      getDemands(shoppingProcesSelect.id, 50, shoppingProcesSelect);
    }

    setProductsToSearch([]);
  }, []);

  if (loading) {
    return <CircularLoading />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="body1">
              Spend (GMV) Inicial Estimado
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              size="small"
              label="Spend (GMV) Inicial Estimado"
              disabled
              value={gmvInitialTotal?.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                Swal.fire({
                  title: translations[param]["attention"],
                  text: "Deseja reenviar o email aos participantes com os novos itens ?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: palette.primary.main,
                  cancelButtonColor: palette.error.main,
                  confirmButtonText: translations[param]["yes"],
                  cancelButtonText: translations[param]["no"],
                }).then((result) => {
                  if (result.isConfirmed) {
                    requestDemands(
                      shoppingProcesSelect,
                      participatingCompanies
                    );
                  }
                })
              }
            >
              Enviar E-mail
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              onClick={() => generateCSV(demands, shoppingProcesSelect)}
            >
              Exportar Consolidado
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              onClick={() => setOpenDialogSecondary(true)}
            >
              Exportar Hospital
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Demandas</Typography>
        <Divider
          sx={{ borderBottomWidth: 2, borderColor: theme.palette.primary.main }}
        />
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Código</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Volume Mensal</TableCell>
                <TableCell>Volume Total Vigência</TableCell>
                <TableCell>Spend (GMV) Inicial</TableCell>
                <TableCell>
                  <Checkbox
                    checked={
                      demands.length > 0 &&
                      itemsToTrade.length === demands.length
                    }
                    indeterminate={
                      itemsToTrade.length > 0 &&
                      itemsToTrade.length < demands.length
                    }
                    onChange={(e) => {
                      if (!e.target.checked) {
                        return setItemsToTrade([]);
                      }
                      setItemsToTrade([...demands]);
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {demands.map((demand: any, index: number) => (
                <>
                  <TableRow
                    key={demand.produtoCodigo}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell onClick={() => handleRowClick(index)}>
                      <IconButton size="small">
                        {openRow === index ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>{demand.produtoCodigo}</TableCell>
                    <TableCell>{demand.produtoDescricao}</TableCell>
                    <TableCell>
                      {demand?.solicitacaoCompras
                        ?.map((item: any, _: number) => {
                          return {
                            quantidade: item.quantidade,
                          };
                        })
                        .reduce(
                          (accumulator: any, currentValue: any) =>
                            accumulator + currentValue.quantidade,
                          0
                        )
                        ?.toLocaleString("pt-br", {
                          currency: "BRL",
                        })}
                    </TableCell>
                    <TableCell>
                      {(
                        demand?.solicitacaoCompras
                          ?.map((item: any, _: number) => {
                            return {
                              quantidade: item.quantidade,
                            };
                          })
                          .reduce(
                            (accumulator: any, currentValue: any) =>
                              accumulator + currentValue.quantidade,
                            0
                          ) * parseInt(shoppingProcesSelect?.monthNumber ?? "")
                      )?.toLocaleString("pt-br", {
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell>
                      {demand.gmvInicial?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={
                          !!itemsToTrade.find((e: any) => e.id === demand.id)
                        }
                        onChange={(e) => {
                          if (!e.target.checked) {
                            return setItemsToTrade(
                              itemsToTrade.filter(
                                (e: any) => e.id !== demand.id
                              )
                            );
                          }
                          setItemsToTrade([...itemsToTrade, demand]);
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={openRow === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          {!demand?.solicitacaoCompras ? (
                            <></>
                          ) : (
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>CNPJ</TableCell>
                                  <TableCell>Razão Social</TableCell>
                                  <TableCell>Código do Item</TableCell>
                                  <TableCell>Descrição do Item</TableCell>
                                  <TableCell>Marcas</TableCell>
                                  <TableCell>Volume Mensal</TableCell>
                                  <TableCell>Volume Total Vigência</TableCell>
                                  <TableCell>Valor Referência</TableCell>
                                  <TableCell>Valor Alvo</TableCell>
                                  <TableCell />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {demand?.solicitacaoCompras?.map(
                                  (item: any, subIndex: number) => (
                                    <TableRow
                                      key={subIndex}
                                      sx={
                                        item?.processoCompraItemId
                                          ? { backgroundColor: "#cff3ba" }
                                          : { backgroundColor: "none" }
                                      }
                                    >
                                      <TableCell>
                                        {item?.empresaSolicitacao?.cnpj}
                                      </TableCell>
                                      <TableCell>
                                        {item?.empresaSolicitacao?.razaoSocial}
                                      </TableCell>
                                      <TableCell>
                                        {
                                          item.produtoEmpresa
                                            .codigoProdutoEmpresa
                                        }
                                      </TableCell>
                                      <TableCell>
                                        {item.produtoEmpresa.descricao}
                                      </TableCell>
                                      <TableCell>
                                        {item.solicitacaoMarcas
                                          ?.map((i: any) => i?.descricao)
                                          ?.join(";")}
                                      </TableCell>
                                      <TableCell>
                                        {item.quantidade?.toLocaleString(
                                          "pt-br",
                                          {
                                            currency: "BRL",
                                          }
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {(
                                          item.quantidade *
                                          parseInt(
                                            shoppingProcesSelect?.monthNumber ??
                                              ""
                                          )
                                        )?.toLocaleString("pt-br", {
                                          currency: "BRL",
                                        })}
                                      </TableCell>
                                      <TableCell>
                                        {item.valorReferencia?.toLocaleString(
                                          "pt-br",
                                          {
                                            style: "currency",
                                            currency: "BRL",
                                          }
                                        ) ?? "R$ 0,00"}
                                      </TableCell>
                                      <TableCell>
                                        {item.valorAlvo?.toLocaleString(
                                          "pt-br",
                                          {
                                            style: "currency",
                                            currency: "BRL",
                                          }
                                        ) ?? "R$ 0,00"}
                                      </TableCell>
                                      {item?.processoCompraItemId ? (
                                        <TableCell>
                                          <CheckCircleOutlineOutlinedIcon color="success" />
                                        </TableCell>
                                      ) : (
                                        <TableCell>
                                          <IconButton size="small">
                                            <Close
                                              color="error"
                                              onClick={() =>
                                                Swal.fire({
                                                  title:
                                                    translations[param][
                                                      "attention"
                                                    ],
                                                  text: `deseja continuar ? `,
                                                  icon: "warning",
                                                  showCancelButton: true,
                                                  confirmButtonColor:
                                                    palette.primary.main,
                                                  cancelButtonColor:
                                                    palette.error.main,
                                                  confirmButtonText:
                                                    translations[param]["yes"],
                                                  cancelButtonText:
                                                    translations[param]["no"],
                                                }).then((result) => {
                                                  if (result.isConfirmed)
                                                    remove(
                                                      {
                                                        ...item,
                                                        processoCompraId: null,
                                                      },
                                                      demand?.solicitacaoCompras,
                                                      demands,
                                                      demand.id
                                                    );
                                                })
                                              }
                                            />
                                          </IconButton>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            if (itemsToTrade.length === 0)
              return toast.info("Favor selecionar as demandas!");

            setOpenDialogThree(true);
          }}
        >
          Encaminhar para Negociação
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Itens Sugeridos</Typography>
        <Divider
          sx={{ borderBottomWidth: 2, borderColor: theme.palette.primary.main }}
        />
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>CNPJ</TableCell>
                <TableCell>Razão Social</TableCell>
                <TableCell>Código Item</TableCell>
                <TableCell>Descrição Item</TableCell>
                <TableCell>Marca</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Valor Referência</TableCell>
                <TableCell>Valor Alvo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemsSuggstionsCreated.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{item.cnpjEmpresa}</TableCell>
                  <TableCell>{item.nomeFantasiaEmpresa}</TableCell>
                  <TableCell>{item.codigoProduto}</TableCell>
                  <TableCell>{item.descricaoProduto}</TableCell>
                  <TableCell>
                    {item?.marcas?.map((i: any) => i?.descricao).join(";")}
                  </TableCell>
                  <TableCell>{item.quantidade}</TableCell>
                  <TableCell>
                    {parseFloat(item.valorReferencia)?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    }) ?? "0,00"}
                  </TableCell>
                  <TableCell>
                    {parseFloat(item.valorAlvo)?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    }) ?? "0,00"}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "100px",
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => {
                        setItemVinculation({
                          ...itemVinculation,
                          codigoItemComprador: item?.codigoProduto ?? "",
                          descricaoItemComprador: item?.descricaoProduto ?? "",
                          solicitacaoCompra: {
                            ...item,
                            sugestao: false,
                          },
                        });
                        setOpenDialog(true);
                      }}
                    >
                      <Add color="primary" />
                    </IconButton>
                    <IconButton size="small">
                      <Close
                        color="error"
                        onClick={() =>
                          Swal.fire({
                            title: translations[param]["attention"],
                            text: `deseja continuar ? `,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: palette.primary.main,
                            cancelButtonColor: palette.error.main,
                            confirmButtonText: translations[param]["yes"],
                            cancelButtonText: translations[param]["no"],
                          }).then((result) => {
                            if (result.isConfirmed)
                              remove(
                                {
                                  ...item,
                                  processoCompraId: null,
                                },
                                item?.solicitacaoCompras,
                                demands,
                                item.id
                              );
                          })
                        }
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Fornecedores Sugeridos</Typography>
        <Divider
          sx={{ borderBottomWidth: 2, borderColor: theme.palette.primary.main }}
        />
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>CNPJ</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>CNPJ Sugestão</TableCell>
                <TableCell>Nome Sugestão</TableCell>
                <TableCell>Nome Contato</TableCell>
                <TableCell>E-mail Contato</TableCell>
                <TableCell>Telefone Contato</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {participatingCompanies.map((item: any, index: number) =>
                item?.sugestoes?.map((el: any) => (
                  <TableRow key={index}>
                    <TableCell>{maskCnpj(item.cnpj)}</TableCell>
                    <TableCell>{item.razaoSocial}</TableCell>
                    <TableCell>{el.cnpjSugestao}</TableCell>
                    <TableCell>{el.razaoSocialSugestao}</TableCell>
                    <TableCell>{el.nomeContatoSugestao}</TableCell>
                    <TableCell>{el.emailContatoSugestao}</TableCell>
                    <TableCell>{el.telefoneContatoSugestao}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <SuggestedItemForm />
      </DialogComponent>

      <DialogComponent open={openDialogSecondary} handleClose={() => {}}>
        <SelectedHospital
          items={demands
            .filter(
              (item: any) => item.solicitacaoCompras && item.solicitacaoCompras
            )
            .flatMap((item: any) => item.solicitacaoCompras)}
        />
      </DialogComponent>

      <DialogComponent open={openDialogThree} handleClose={() => {}}>
        <DialogPaymentCondition />
      </DialogComponent>
    </Grid>
  );
};

export default ConsolidateDemand;
