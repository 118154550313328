/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Card,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useParticipatingCompanies from "src/hooks/useParticipatingCompanies";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";
import { useEffect } from "react";
import useCompanyContact from "src/hooks/useCompanyContact";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import useShoppingProcess from "src/hooks/useShoppingProcess";

const DialogContacts = ({ item }: { item: any }) => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    setOpenDialogContacts,
    contactsSelected,
    setContactsSelected,
    handleSaveNewConstacts,
    handleDeleteConstacts,
  } = useParticipatingCompanies();

  const { shoppingProcesSelect } = useShoppingProcess();

  const {
    getCompanysContacts,
    loading,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    companysContactToSearch,
  } = useCompanyContact();

  const header = [
    {
      label: translations[param]["name"],
    },
    {
      label: translations[param]["email"],
    },
    {
      label: "Ações",
    },
  ];

  useEffect(() => {
    setContactsSelected([]);
    if (item?.empresasId) {
      getCompanysContacts(item.empresasId, undefined, 1000);
    }
  }, [page.page, page.rowsPerPage]);

  const handleDelete = (chipToDelete: any) => () => {
    const constacts = contactsSelected.filter(
      (chip: any) => chip.id !== chipToDelete.id
    );

    setContactsSelected(constacts);
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">Contatos Adicionais</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setOpenDialogContacts(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {loading ? (
        <CircularLoading />
      ) : (
        <DialogContent>
          <Autocomplete
            multiple
            disablePortal
            id="show_select_administrator_responsible"
            options={companysContactToSearch}
            value={contactsSelected}
            getOptionLabel={(option) => `${option?.label}`}
            onChange={(_, value) => {
              const newContacts = value.filter(
                (option) =>
                  !contactsSelected.some(
                    (contact: any) => contact.id === option.id
                  )
              );
              setContactsSelected([
                ...contactsSelected,
                ...newContacts.map((option) => ({
                  ...option,
                  processoComprasParticipantesId: item.id,
                })),
              ]);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.label}
                  {...getTagProps({ index })}
                  onDelete={handleDelete(option)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione um contato:"
                size="small"
              />
            )}
            fullWidth
          />
          {item?.contatos?.length === 0 ? (
            <Empty />
          ) : (
            <Card sx={{ padding: 2, marginTop: 5 }}>
              <TableComponent
                header={header}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {item?.contatos?.map((data: any) => (
                  <TableRow
                    hover
                    key={data?.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {data?.nomeContatoComprador}
                    </TableCell>
                    <TableCell>{data?.emailContatoComprador}</TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        Swal.fire({
                          title: translations[param]["attention"],
                          text: "Deseja remover o contato ?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: translations[param]["yes"],
                          cancelButtonText: translations[param]["no"],
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleDeleteConstacts(
                              data.id,
                              shoppingProcesSelect!.id
                            );
                          }
                        });
                      }}
                    >
                      <DeleteIcon color="error" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            </Card>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <ButtonDefault
          label="Fechar"
          onClick={() => {
            setOpenDialogContacts(false);
          }}
        />
        <ButtonSalveForm
          label="Salvar"
          onClick={() => handleSaveNewConstacts(shoppingProcesSelect!.id)}
        />
      </DialogActions>
    </>
  );
};

export default DialogContacts;
