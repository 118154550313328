import { iCompanyContact } from "src/interfaces/companyContact";

export default class CompanyContact implements iCompanyContact {
  id?: string | null;
  name: string;
  email: string;
  phone: string;
  office: string;
  representative: boolean;
  cpf: string;
  companyId?: string | null;
  areaId?: string | null;
  main?: boolean;
  cell?: string;
  typeId?: string;
  type?: string;
  redesSociais?: any[];
  active?: boolean;
  enviarPreFaturamento?: boolean;

  constructor(
    id: string | null,
    name: string,
    email: string,
    phone: string,
    office: string,
    representative: boolean,
    cpf: string,
    companyId?: string | null,
    areaId?: string | null,
    main?: boolean,
    cell?: string,
    typeId?: string,
    type?: string,
    redesSociais?: any[],
    active?: boolean,
    enviarPreFaturamento?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.office = office;
    this.representative = representative;
    this.cpf = cpf;
    this.companyId = companyId;
    this.areaId = areaId;
    this.main = main;
    this.cell = cell;
    this.typeId = typeId;
    this.type = type;
    this.redesSociais = redesSociais;
    this.active = active;
    this.enviarPreFaturamento = enviarPreFaturamento;
  }

  public static adapterToClass(companyContact: any) {
    return new CompanyContact(
      companyContact.id,
      companyContact.nome,
      companyContact.email,
      companyContact.telefone,
      companyContact.cargo,
      companyContact.representante,
      companyContact.cpf,
      companyContact.empresaId,
      companyContact.areaId,
      companyContact.principal,
      companyContact.celular,
      companyContact.contatosEmpresaTipoId,
      companyContact.contatosEmpresaTipo,
      companyContact.redesSociais,
      companyContact.ativo,
      companyContact.enviarPreFaturamento
    );
  }

  public static numberToString(value: string) {
    const rolesMap = new Map([
      ["05", "Administrador"],
      ["08", "Conselheiro de Administração"],
      ["10", "Diretor"],
      ["16", "Presidente"],
      ["17", "Procurador"],
      ["18", "Secretário"],
      ["20", "Sociedade Consorciada"],
      ["21", "Sociedade Filiada"],
      ["22", "Sócio"],
      ["23", "Sócio Capitalista"],
      ["24", "Sócio Comanditado"],
      ["25", "Sócio Comanditário"],
      ["26", "Sócio de Indústria"],
      ["28", "Sócio-Gerente"],
      ["29", "Sócio Incapaz ou Relat.Incapaz (exceto menor)"],
      ["30", "Sócio Menor (Assistido/Representado)"],
      ["31", "Sócio Ostensivo"],
      ["33", "Tesoureiro"],
      ["37", "Sócio Pessoa Jurídica Domiciliado no Exterior"],
      ["38", "Sócio Pessoa Física Residente ou Domiciliado no Exterior"],
      ["47", "Sócio Pessoa Física Residente no Brasil"],
      ["48", "Sócio Pessoa Jurídica Domiciliado no Brasil"],
      ["49", "Sócio-Administrador"],
      ["52", "Sócio com Capital"],
      ["53", "Sócio sem Capital"],
      ["54", "Fundador"],
      ["55", "Sócio Comanditado Residente no Exterior"],
      ["56", "Sócio Comanditário Pessoa Física Residente no Exterior"],
      ["57", "Sócio Comanditário Pessoa Jurídica Domiciliado no Exterior"],
      ["58", "Sócio Comanditário Incapaz"],
      ["59", "Produtor Rural"],
      ["63", "Cotas em Tesouraria"],
      ["65", "Titular Pessoa Física Residente ou Domiciliado no Brasil"],
      ["66", "Titular Pessoa Física Residente ou Domiciliado no Exterior"],
      [
        "67",
        "Titular Pessoa Física Incapaz ou Relativamente Incapaz (exceto menor)",
      ],
      ["68", "Titular Pessoa Física Menor (Assistido/Representado)"],
      ["70", "Administrador Residente ou Domiciliado no Exterior"],
      [
        "71",
        "Conselheiro de Administração Residente ou Domiciliado no Exterior",
      ],
      ["72", "Diretor Residente ou Domiciliado no Exterior"],
      ["73", "Presidente Residente ou Domiciliado no Exterior"],
      ["74", "Sócio-Administrador Residente ou Domiciliado no Exterior"],
      ["75", "Fundador Residente ou Domiciliado no Exterior"],
      ["76", "Protetor"],
      ["77", "Vice-Presidente"],
      ["78", "Titular Pessoa Jurídica Domiciliada no Brasil"],
      ["79", "Titular Pessoa Jurídica Domiciliada no Exterior"],
    ]);

    return rolesMap.get(value);
  }

  public static adapterToJson(company: any) {
    return JSON.parse(company);
  }

  public static adapterToBody(companyContact: iCompanyContact) {
    const data: any = {
      nome: companyContact.name,
      email: companyContact.email,
      telefone: companyContact.phone,
      cargo: companyContact.office,
      representante: companyContact.representative,
      empresaId: companyContact.companyId,
      principal: companyContact.main,
      enviarPreFaturamento: companyContact.enviarPreFaturamento,
    };

    if (companyContact?.id) {
      data["id"] = companyContact?.id;
    }

    if (companyContact?.active) {
      data["ativo"] = companyContact?.active;
    }

    if (companyContact?.cell) {
      data["celular"] = companyContact?.cell;
    }

    if (companyContact?.cpf) {
      data["cpf"] = companyContact.cpf;
    }

    if (companyContact?.areaId && companyContact?.areaId !== "select") {
      data["areaId"] = companyContact?.areaId;
    }

    if (companyContact?.typeId && companyContact?.typeId !== "select") {
      data["contatosEmpresaTipoId"] = companyContact?.typeId;
    }

    return data;
  }
}
