/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import useInvoicing from "src/hooks/useInvoicing";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonDefault, ButtonSendMail } from "src/components/ButtonsCommons";
import { MaskStyle } from "src/styles/globalStyle";

const SendMail: React.FC = () => {
  const {
    setOpenDialogSendMail,
    periodoFaturamentoEnvioEmail,
    setPeriodoFaturamentoEnvioEmail,
    inputValue,
    setInputValue,
    handleKeyDown,
    handleDeleteMail,
    sendMailToSave,
    destinatariosEnvioEmail,
    setDestinatariosEnvioEmail,
  } = useInvoicing();

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        Enviar E-mail Pré-Faturamento
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setDestinatariosEnvioEmail([]);
          setPeriodoFaturamentoEnvioEmail("");
          setInputValue("");
          setOpenDialogSendMail(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid xs={12} sm={12} md={12} lg={6} item>
            <FormLabel>Digite um email: </FormLabel>
            <TextField
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Aperte 'Enter' para confirmar o email"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} item>
            <FormLabel>Período Faturamento: </FormLabel>
            <FormControl fullWidth>
              <MaskStyle>
                <input
                  type="date"
                  name="begin_date"
                  placeholder="dd/mm/yyyy"
                  min="1970-01-01"
                  value={periodoFaturamentoEnvioEmail}
                  onChange={(e) => {
                    setPeriodoFaturamentoEnvioEmail(e.target.value);
                  }}
                />
              </MaskStyle>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} item>
            <FormLabel>Destinatários: </FormLabel>
            {destinatariosEnvioEmail.map((email, index) => (
              <Chip
                label={email}
                key={index}
                onDelete={() => handleDeleteMail(email)}
              />
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDefault
          label="Fechar"
          onClick={() => {
            setOpenDialogSendMail(false);
            setDestinatariosEnvioEmail([]);
            setPeriodoFaturamentoEnvioEmail("");
            setInputValue("");
          }}
        />
        <ButtonSendMail
          label="Enviar E-mail"
          onClick={() => {
            sendMailToSave();

            setDestinatariosEnvioEmail([]);
            setPeriodoFaturamentoEnvioEmail("");
            setInputValue("");
          }}
        />
      </DialogActions>
    </>
  );
};

export default SendMail;
