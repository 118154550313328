import Button from "@mui/material/Button";

import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";

import { iButttonsCommons } from "src/interfaces/buttonsCommons";

export const ButtonDefault = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="secondary"
      sx={
        props?.fontSize
          ? { marginRight: "1%", fontSize: props?.fontSize }
          : { margin: "1%" }
      }
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};

export const ButtonInclude = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="secondary"
      startIcon={<AddIcon color="primary" />}
      sx={
        props.marginRight
          ? { marginRight: props.marginRight }
          : { marginRight: "1%" }
      }
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};

export const ButtonSendMail = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="secondary"
      startIcon={<ForwardToInboxOutlinedIcon color="primary" />}
      sx={
        props.marginRight
          ? { marginRight: props.marginRight }
          : { marginRight: "1%" }
      }
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};

export const ButtonActive = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="secondary"
      startIcon={<CheckCircleIcon color="primary" />}
      sx={{ marginRight: "1%" }}
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};

export const ButtonInactive = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="secondary"
      startIcon={<DoDisturbIcon color="error" />}
      sx={{ marginRight: "1%" }}
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};

export const ButtonDelete = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="secondary"
      startIcon={<DeleteIcon color="error" />}
      sx={{ marginRight: "1%" }}
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};

export const ButtonSearch = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="primary"
      startIcon={<SearchIcon color="secondary" />}
      sx={{ marginRight: "1%" }}
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};

export const ButtonOrderGenerate = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="secondary"
      startIcon={<PostAddIcon color="primary" />}
      sx={{ marginRight: "1%" }}
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};

export const ButtonSalveForm = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="secondary"
      startIcon={<DoneIcon color={props.colorIcon ?? "primary"} />}
      sx={{ marginRight: "1%", ...props.sx }}
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};

export const ButtonClosedForm = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="secondary"
      startIcon={<CloseIcon color="error" />}
      sx={{ marginRight: "1%" }}
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};

export const ButtonReturn = (props: iButttonsCommons) => {
  return (
    <Button
      variant="contained"
      size={props.size ?? "small"}
      onClick={props.onClick}
      color="secondary"
      startIcon={
        <KeyboardReturnOutlinedIcon color={props.colorIcon ?? "info"} />
      }
      sx={{ marginRight: "1%" }}
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
    >
      {props.label}
    </Button>
  );
};
