/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import useInvoicing from "src/hooks/useInvoicing";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonDefault } from "src/components/ButtonsCommons";
import TableComponent from "src/components/TableComponent";
import Empty from "src/components/Empty";
import { format } from "date-fns/esm";

const headerOfDialog = [
  {
    label: "Data de Envio",
  },
  {
    label: "remetente",
  },
  {
    label: "Destinatário",
  },
  {
    label: "Assunto",
  },
  {
    label: "Status",
  },
];

const LogMail: React.FC = () => {
  const {
    setOpenDialogLogMail,
    logsEmails,
    setLogsEmails,
    pageSendMails,
    handleChangePageSendMails,
    handleChangeRowsPerPageSendMails,
    logSelect,
    setLogSelect,
  } = useInvoicing();

  return (
    <>
      <DialogTitle id="alert-dialog-title">Log de E-mails Enviados</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setLogSelect("");
          setLogsEmails([]);
          setOpenDialogLogMail(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {logsEmails?.length === 0 ? (
          <Empty />
        ) : (
          <>
            <TableComponent
              header={headerOfDialog}
              page={pageSendMails}
              handleChangePage={handleChangePageSendMails}
              handleChangeRowsPerPage={handleChangeRowsPerPageSendMails}
            >
              {logsEmails?.map((item: any) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                    backgroundColor: logSelect === item ? "#e0e0e0" : "",
                  }}
                  onClick={() => {
                    setLogSelect(item?.conteudo);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {item?.dataEnvio
                      ? format(new Date(item?.dataEnvio), "dd/MM/yyyy")
                      : ""}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item?.remetente}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item?.destinatario}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item?.assunto}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item?.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>

            <div dangerouslySetInnerHTML={{ __html: logSelect }} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonDefault
          label="Fechar"
          onClick={() => {
            setLogSelect("");
            setOpenDialogLogMail(false);
            setLogsEmails([]);
          }}
        />
      </DialogActions>
    </>
  );
};

export default LogMail;
