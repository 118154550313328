/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Grid,
  Box,
  Autocomplete,
} from "@mui/material";
import React, { useEffect } from "react";
import ReactInputMask from "react-input-mask";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import {
  ButtonActive,
  ButtonClosedForm,
  ButtonInactive,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import { maskForManyTypesString } from "src/functions/text";
import useCompanyContact from "src/hooks/useCompanyContact";
import { iCompanyContact } from "src/interfaces/companyContact";
import { MaskStyle } from "src/styles/globalStyle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useCompany from "src/hooks/useCompany";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import SocialMediaContact from "./SocialMediaContact";

const options: any = [
  { label: "Analista" },
  { label: "Analista em Compras" },
  { label: "Administrador" },
  { label: "CEO" },
  { label: "CIO" },
  { label: "CTO" },
  { label: "Comercial" },
  { label: "Comprador" },
  { label: "Conselheiro de Administração" },
  { label: "Coordenador" },
  { label: "Coordenador de Compras" },
  { label: "Coordenador de Suprimentos" },
  { label: "Diretor" },
  { label: "Diretor Administrativo" },
  { label: "Diretor Financeiro" },
  { label: "Diretor de Suprimentos" },
  { label: "Diretora de operações" },
  { label: "Especialista em Compras" },
  { label: "Gerente" },
  { label: "Gerente Administrativo" },
  { label: "Gerente de Compras" },
  { label: "Gerente de Suprimentos" },
  { label: "Gerente de Farmácia" },
  { label: "Presidente" },
  { label: "Supervisor" },
  { label: "Supervisor de Compras" },
  { label: "Supervisor de Materiais" },
  { label: "Supervisor de Suprimentos" },
  { label: "Sócio" },
  { label: "Sócio Menor (Assistido/Representado)" },
  { label: "Sócio Pessoa Jurídica Domiciliado no Exterior" },
  { label: "Sócio-Administrador" },
  { label: "TI" },
  { label: "Vendas" },
  { label: "Vendedor" },
];

const Contacts: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    companyContactSelected,
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    validationErrorEmail,
    handleSelect,
    companyContacts,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    getCompanysContacts,
    handleSalve,
    handleUpdate,
    areas,
    area,
    setArea,
    office,
    setOffice,
    main,
    setMain,
    representative,
    setRepresentative,
    cpf,
    setCpf,
    cell,
    setCell,
    types,
    typeId,
    setTypeId,
    getRedesSociais,
    enviarPreFaturamento,
    setEnviarPreFaturamento,
  } = useCompanyContact();

  const { companySelected } = useCompany();

  const header = [
    {
      label: translations[param]["name"],
    },
    {
      label: translations[param]["phone"],
    },
    {
      label: translations[param]["email"],
    },
    {
      label: translations[param]["position"],
    },
    {
      label: translations[param]["representative"],
    },
    {
      label: translations[param]["main"],
    },
    {
      label: "Ativo",
    },
  ];

  useEffect(() => {
    getRedesSociais();
  }, []);

  useEffect(() => {
    getCompanysContacts(companySelected?.id);
  }, [page.page, page.rowsPerPage]);

  return (
    <>
      <Card sx={{ padding: 5, width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!companyContactSelected?.id) {
              handleSalve(companySelected?.id);
            } else {
              handleUpdate(companySelected?.id);
            }
          }}
        >
          <Grid container>
            <Grid xs={12} md={12} lg={12} item>
              <FormLabel>{translations[param]["name"]}: </FormLabel>

              <TextField
                id="name"
                name="name"
                fullWidth
                label={translations[param]["name"]}
                required
                variant="outlined"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid xs={12} md={12} lg={4} item>
              <FormLabel>{translations[param]["cpf"]}: </FormLabel>
              <FormControl fullWidth>
                <MaskStyle>
                  <ReactInputMask
                    mask="999.999.999-99"
                    name="cpf"
                    placeholder={translations[param]["cpf"]}
                    value={cpf}
                    style={{ width: "99%" }}
                    onChange={(e) => setCpf(e.target.value)}
                  />
                </MaskStyle>
              </FormControl>
            </Grid>

            <Grid xs={12} md={12} lg={4} item>
              <FormLabel>{translations[param]["phone"]}: </FormLabel>

              <FormControl fullWidth>
                <MaskStyle>
                  <ReactInputMask
                    mask="(99) 99999-9999"
                    style={{ width: "99%" }}
                    name="phone"
                    placeholder={translations[param]["phone"]}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </MaskStyle>
              </FormControl>
            </Grid>

            <Grid xs={12} md={12} lg={4} item>
              <FormLabel>{translations[param]["cell"]}: </FormLabel>

              <FormControl fullWidth>
                <MaskStyle>
                  <ReactInputMask
                    mask="(99) 99999-9999"
                    name="cell"
                    placeholder={translations[param]["cell"]}
                    value={cell}
                    onChange={(e) => setCell(e.target.value)}
                  />
                </MaskStyle>
              </FormControl>
            </Grid>

            <Grid xs={12} md={12} lg={4} item>
              <FormLabel>{translations[param]["email"]}: </FormLabel>

              <TextField
                id="email"
                name="email"
                label={translations[param]["email"]}
                required
                fullWidth
                value={email.toLowerCase()}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ width: "99%" }}
                error={validationErrorEmail}
                helperText={
                  validationErrorEmail
                    ? translations[param]["invalid_email_message"]
                    : ""
                }
              />
            </Grid>

            <Grid xs={12} md={12} lg={4} item>
              <FormLabel>{translations[param]["position"]}: </FormLabel>

              <Autocomplete
                disablePortal
                options={options}
                id="cargo"
                size="small"
                value={office}
                fullWidth
                onChange={(_: any, value: any) => {
                  if (value) {
                    setOffice(value.label);
                  } else {
                    setOffice("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="cargo"
                    label={translations[param]["position"]}
                    variant="outlined"
                    required
                    onChange={(e: any) => setOffice(e.target.value)}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} lg={4} item>
              <FormLabel>{translations[param]["area"]}: </FormLabel>

              <Select
                fullWidth
                labelId="show_select_type_state"
                id="show_select_type_state"
                value={area}
                required
                size="small"
                onChange={(event: SelectChangeEvent) => {
                  setArea(event.target.value as string);
                }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value="select" disabled>
                  {translations[param]["select"]}:{" "}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {areas.map((item) => (
                  <MenuItem key={item.id} value={`${item.id}`}>
                    {item.descricao}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid xs={12} md={12} lg={4} item>
              <FormLabel>{translations[param]["type"]}: </FormLabel>

              <Select
                fullWidth
                labelId="show_select_type_state"
                id="show_select_type_state"
                value={typeId}
                required
                size="small"
                onChange={(event: SelectChangeEvent) => {
                  setTypeId(event.target.value as string);
                }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value="select" disabled>
                  {translations[param]["select"]}:{" "}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {types.map((item: any) => (
                  <MenuItem key={item.id} value={`${item.id}`}>
                    {item.descricao}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid
              xs={12}
              md={12}
              lg={8}
              display="flex"
              justifyContent="end"
              alignItems="flex-end"
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="active"
                      name="active"
                      size="small"
                      checked={enviarPreFaturamento}
                      onChange={(e) =>
                        setEnviarPreFaturamento(e.target.checked)
                      }
                    />
                  }
                  label="Enviar Pré Faturamento"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="active"
                      name="active"
                      size="small"
                      checked={representative}
                      onChange={(e) => setRepresentative(e.target.checked)}
                    />
                  }
                  label={translations[param]["legal_representative"]}
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="active"
                      name="active"
                      size="small"
                      checked={main}
                      onChange={(e) => setMain(e.target.checked)}
                    />
                  }
                  label={translations[param]["main"]}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%", marginTop: 5, marginBottom: 5 }}>
            <SocialMediaContact />
          </Box>

          <Grid container justifyContent="end" sx={{ marginTop: 3 }}>
            <ButtonClosedForm
              label={translations[param]["clear_form"]}
              onClick={() => handleSelect(null)}
            />
            <FormControl>
              {companyContactSelected?.id ? (
                <ButtonSalveForm
                  label={translations[param]["update"]}
                  type="submit"
                />
              ) : (
                <ButtonInclude
                  label={translations[param]["include"]}
                  type="submit"
                />
              )}
            </FormControl>
          </Grid>
        </form>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          {companyContacts.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {companyContacts?.map((data: iCompanyContact) => (
                <TableRow
                  hover
                  key={data?.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleSelect(data)}
                  >
                    {data?.name}
                  </TableCell>
                  <TableCell onClick={() => handleSelect(data)}>
                    {maskForManyTypesString(
                      data?.phone?.replace(/[^a-zA-Z0-9]/g, "") ?? "",
                      "(##) #####-####"
                    )}
                  </TableCell>
                  <TableCell onClick={() => handleSelect(data)}>
                    {data?.email}
                  </TableCell>
                  <TableCell onClick={() => handleSelect(data)}>
                    {data?.office}
                  </TableCell>
                  <TableCell onClick={() => handleSelect(data)}>
                    {data?.representative ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell onClick={() => handleSelect(data)}>
                    {data?.main ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    {data.active ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    {!data.active ? (
                      <ButtonActive
                        label={translations[param]["activate"]}
                        disabled={companyContactSelected?.id !== data.id}
                        onClick={() =>
                          handleUpdate(companySelected?.id, "true")
                        }
                      />
                    ) : (
                      <ButtonInactive
                        label={translations[param]["inactive"]}
                        disabled={companyContactSelected?.id !== data.id}
                        onClick={() =>
                          handleUpdate(companySelected?.id, "false")
                        }
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default Contacts;
