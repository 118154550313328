/* eslint-disable react-hooks/exhaustive-deps */
import { addHours, format } from "date-fns";
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { maskCnpj } from "src/functions/text";
import usePaymentCondition from "src/hooks/usePaymentCondition";
import { iCompany } from "src/interfaces/company";
import { iPage } from "src/interfaces/layout";
import {
  iRequestContextProps,
  iRequests,
  iRequestsItems,
} from "src/interfaces/requests";
import Requests from "src/models/Requests";
import invoicesConsumer from "src/services/invoices";
import requestConsumer from "src/services/request";
import shoppingProcessConsumer from "src/services/shoppingProcess";

const RequestContext = createContext<iRequestContextProps>(
  {} as iRequestContextProps
);

export function RequestProvider({ children }: { children: any }) {
  const { getPaymentConditions } = usePaymentCondition();

  const [loading, setLoading] = useState<boolean>(false);
  const [requests, setRequests] = useState<Array<iRequests>>([]);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [requestSelect, setRequestSelect] = useState<iRequests | null>(null);
  const [requestSelectSearch, setRequestSelectSearch] = useState<any>(null);
  const [activeTabsMenu, setActiveTabsMenu] = useState<boolean>(true);
  const [buyerSelect, setBuyerSelect] = useState<any>(null);
  const [supplierSelect, setSupplierSelect] = useState<any>(null);
  const [number, setNumber] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const [emissionDate, setEmissionDate] = useState<string>("");
  const [deliveryDate, setDeliveryDate] = useState<string>("");
  const [paymentPrevisionDate, setPaymentPrevisionDate] = useState<string>("");
  const [supplierId, setSupplierId] = useState<string>("");
  const [supplier, setSupplier] = useState<string>("");
  const [buyerId, setBuyerId] = useState<string>("");
  const [buyer, setBuyer] = useState<string>("");
  const [invoicingId, setInvoicingId] = useState<string>("");
  const [shoppingProccessId, setShoppingProccessId] = useState<string>("");
  const [shoppingProccessDescription, setShoppingProccessDescription] =
    useState<string>("");
  const [invoceId, setInvoceId] = useState<string>("");
  const [items, setItems] = useState<Array<iRequestsItems>>([]);
  const [itemsToSearch, setItemsToSearch] = useState<Array<any>>([]);
  const [CSV, setCSV] = useState<Array<any>>([]);
  const [CSVSupply, setCSVSupply] = useState<Array<any>>([]);
  const [CSVGrid, setCSVGrid] = useState<Array<any>>([]);

  const [statusFilter, setStatusFilter] = useState<string>("select");
  const [status, setStatus] = useState<string>("select");
  const [paymentCondition, setPaymentCondition] = useState<string>("select");
  const [proccessNumberFilter, setProccessNumberFilter] = useState<string>("");
  const [descriptionFilter, setDescriptionFilter] = useState<string>("");
  const [requestNumberFilter, setRequestNumberFilter] = useState<string>("");
  const [companyFilter, setCompanyFilter] = useState<string>("");
  const [NfFilter, setNfFilter] = useState<string>("");
  const [codeItemFilter, setCodeItemFilter] = useState<string>("");
  const [descriptionItemFilter, setDescriptionItemFilter] =
    useState<string>("");
  const [startDateFilter, setStartDateFilter] = useState<string>("");
  const [startDateFilterNf, setStartDateFilterNf] = useState<string>("");
  const [endDateFilter, setEndDateFilter] = useState<string>("");
  const [endDateFilterNf, setEndDateFilterNf] = useState<string>("");
  const [orderBy, setOrderBy] = useState<any>({ number: 3, order: true });

  //Types: emission, emissonNF
  const [typeFilterDate, setTypeFilterDate] = useState<string>("emission");

  const [observationStatusReqeuest, setObservationStatusRequest] =
    useState<string>("");

  const [buyers, setBuyers] = useState<Array<any>>([]);
  const [suppliers, setSuppliers] = useState<Array<any>>([]);
  const [itemsPhaseShoppingProccess, setItemsPhaseShoppingProccess] = useState<
    Array<any>
  >([]);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const cleanFilters = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      setRequestSelect(null);
      setStatusFilter("select");
      setProccessNumberFilter("");
      setDescriptionFilter("");
      setRequestNumberFilter("");
      setCompanyFilter("");
      setNfFilter("");
      setCodeItemFilter("");
      setDescriptionFilter("");
      setStartDateFilter("");
      setStartDateFilterNf("");
      setEndDateFilter("");
      setEndDateFilterNf("");
      setShoppingProccessId("");
      setTypeFilterDate("emission");

      resolve();
    });
  };

  const getRequests = async () => {
    setLoading(true);

    try {
      const response = await requestConsumer.get(
        page,
        statusFilter,
        proccessNumberFilter.trim(),
        descriptionFilter.trim(),
        requestNumberFilter.trim(),
        companyFilter.trim(),
        NfFilter.trim(),
        codeItemFilter.trim(),
        descriptionItemFilter.trim(),
        startDateFilter,
        endDateFilter,
        false,
        shoppingProccessId,
        orderBy,
        typeFilterDate,
        startDateFilterNf,
        endDateFilterNf
      );

      if (response.status !== 200) throw response;

      const data = response?.data?.items?.map((item: any) =>
        Requests.adapterToClass(item)
      );

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      const dataCSVGrid = data?.map((item: any) => {
        return {
          "Processo de compras":
            item?.shoppingProccess?.numero ?? "Não informado",
          Número: item.number,
          "Data de emissão": format(
            addHours(new Date(item.emissionDate), 3),
            "dd/MM/yyyy"
          ),
          Comprador: item.buyer.razaoSocial,
          Fornecedor: item.supplier.razaoSocial,
          Valor: item.value,
          "Status atual":
            item?.statusCurrent?.pedidoStatus?.descricao ?? "Não informado",
        };
      });

      setCSVGrid(dataCSVGrid ?? []);
      setRequests(data);
    } catch (e) {
      toast.error("Ops... identificamos um erro ao buscar os pedidos!");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const getRequestsTotal = async () => {
    try {
      setLoading(true);

      const response = await requestConsumer.get(
        {
          page: 0,
          rowsPerPage: 99999,
          total: 0,
        },
        statusFilter,
        proccessNumberFilter.trim(),
        descriptionFilter.trim(),
        requestNumberFilter.trim(),
        companyFilter.trim(),
        NfFilter.trim(),
        codeItemFilter.trim(),
        descriptionFilter.trim(),
        startDateFilter,
        endDateFilter,
        false,
        shoppingProccessId,
        orderBy,
        typeFilterDate,
        startDateFilterNf,
        endDateFilterNf
      );

      if (response.status !== 200) throw response;

      const data = response?.data?.items?.map((item: any) =>
        Requests.adapterToClass(item)
      );

      const dataCSVGrid = data?.map((item: any) => {
        return {
          "Processo de compras":
            item?.shoppingProccess?.numero ?? "Não informado",
          Número: item.number,
          "Data de emissão": format(
            addHours(new Date(item.emissionDate), 3),
            "dd/MM/yyyy"
          ),
          Comprador: item.buyer.razaoSocial,
          Fornecedor: item.supplier.razaoSocial,
          Valor: item.value?.toLocaleString("pt-br", {
            currency: "BRL",
          }),
          "Status atual":
            item?.statusCurrent?.pedidoStatus?.descricao ?? "Não informado",
        };
      });

      setCSVGrid(dataCSVGrid ?? []);
      handleClick();
    } catch (e) {
      toast.error("Ops... identificamos um erro ao buscar os pedidos do CSV!");
    } finally {
      setLoading(false);
    }
  };

  const getRequestsWithoutFilter = async () => {
    setLoading(true);

    try {
      const response = await requestConsumer.get(
        page,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        "",
        orderBy,
        "",
        "",
        ""
      );

      if (response.status !== 200) throw response;

      const data = response?.data?.items?.map((item: any) =>
        Requests.adapterToClass(item)
      );

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setRequests(data);
    } catch (e) {
      toast.error("Ops... identificamos um erro ao buscar os pedidos!");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const handleSelected = async (data: iRequests | null) => {
    setTypeFilterDate("emission");
    setObservationStatusRequest("");

    if (data && data.shoppingProccess?.numero) {
      setRequestSelectSearch({
        id: data.shoppingProccess?.id,
        label: data.shoppingProccess?.descricao,
        code: data.shoppingProccess?.numero,
        rateValue: data.shoppingProccess?.valorTaxa,
        numberMonth: data.shoppingProccess?.numeroMeses,
        buyer: data.shoppingProccess?.administracaoComprador,
      });
    } else {
      setRequestSelectSearch(null);
    }

    const dataCSV = data?.pedidoItens?.map((item) => {
      return {
        "Código da empresa": maskCnpj(data.buyer.cnpj ?? ""),
        "Código do fornecedor": maskCnpj(data.supplier.cnpj ?? ""),
        "Código do item": item?.produtoEmpresaCodigo,
        Quantidade: item.quantidade,
        "Grade de tamanho": "",
      };
    });

    const dataCSVSupply = data?.pedidoItens?.map((item) => {
      return {
        Fornecedor: maskCnpj(data.supplier.cnpj ?? ""),
        "Código do item": item?.produtoEmpresaCodigo, // TODO: para finalizar essa task, precisa dessa informação ser retornada na API, os detalhes estão na task 751
        Quantidade: item.quantidade,
      };
    });

    setCSV(dataCSV ?? []);
    setCSVSupply(dataCSVSupply ?? []);

    if (data?.buyerId) {
      setBuyerSelect({
        id: data.buyerId,
        label: data.buyer?.razaoSocial,
      });
    } else {
      setBuyerSelect(null);
    }

    if (data?.supplierId) {
      setSupplierSelect({
        id: data.supplierId,
        label: data.supplier?.razaoSocial,
      });
    } else {
      setSupplierSelect(null);
    }

    setRequestSelect(data ?? null);
    setNumber(data?.number ?? "");

    if (data?.pedidoItens?.length !== 0) {
      setValue(
        data?.pedidoItens
          ?.map((item: any) => item.valorTotal)
          ?.reduce(
            (accumulator: any, curretValue: any) => (accumulator += curretValue)
          ) ?? 0
      );
    }
    setSupplierId(data?.supplierId ?? "");
    setSupplier(data?.supplier?.nome ?? "");
    setBuyerId(data?.buyerId ?? "");
    setBuyer(data?.buyer?.nome ?? "");
    setInvoicingId(data?.invoicingId ?? "");
    setShoppingProccessId(data?.shoppingProccessId ?? "");
    setShoppingProccessDescription(data?.shoppingProccess?.descricao ?? "");
    setInvoceId(data?.invoceId ?? "");

    if (data?.statusCurrent?.pedidoStatusId) {
      setStatus(data.statusCurrent.pedidoStatusId ?? "select");
    }

    if (data?.emissionDate) {
      setEmissionDate(
        format(addHours(new Date(data.emissionDate), 3), "yyyy-MM-dd")
      );
    } else {
      setEmissionDate("");
    }

    if (data?.paymentPrevisionDate) {
      setPaymentPrevisionDate(
        format(addHours(new Date(data.paymentPrevisionDate), 3), "yyyy-MM-dd")
      );
    } else {
      setPaymentPrevisionDate("");
    }

    if (data?.deliveryDate) {
      setDeliveryDate(
        format(addHours(new Date(data.deliveryDate), 3), "yyyy-MM-dd")
      );
    } else {
      setDeliveryDate("");
    }

    if (data?.shoppingProccessId) {
      await getLastShoppingProccessPhase(data.shoppingProccessId);
    } else {
      setBuyers([]);
      setSuppliers([]);
    }

    if (data?.buyerId) {
      await getPaymentConditions({ id: data.buyerId } as iCompany);
    }

    setPaymentCondition(data?.paymentConditionId ?? "select");
  };

  const getLastShoppingProccessPhase = async (id: string) => {
    setLoading(true);

    try {
      const response =
        await shoppingProcessConsumer.getLastShoppingProccessPhase(id);

      if (response.status === 204) return;

      setItemsPhaseShoppingProccess(
        response?.data?.itensEtapa.map((item: any) => {
          return {
            ...item,
            label: item.descricaoProduto,
            code: item.codigoProduto,
          };
        })
      );

      setBuyers(
        response.data.itensEtapa
          .map((item: any) => {
            return {
              id: item.compradorId,
              label: item.comprador ?? "",
              condicaoPagamentoId: item.condicaoPagamentoId ?? "",
            };
          })
          .filter((value: any, index: number, self: any) => {
            return self.findIndex((v: any) => v.id === value.id) === index;
          })
      );
      setSuppliers(
        response.data.itensEtapa
          .map((item: any) => {
            return {
              id: item.fornecedorId,
              label: item.fornecedor ?? "",
            };
          })
          .filter((value: any, index: number, self: any) => {
            return self.findIndex((v: any) => v.id === value.id) === index;
          })
      );
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar as etapas do processo de compras!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSalveNewRequest = async () => {
    if (!status || status === "select") {
      return toast.warning("Favor informar o status do pedido!");
    }

    try {
      setLoading(true);

      const request = new Requests(
        "",
        number,
        value,
        new Date(emissionDate),
        new Date(deliveryDate),
        new Date(paymentPrevisionDate),
        supplierId,
        buyerId,
        invoicingId,
        shoppingProccessId,
        invoceId,
        [],
        "",
        "",
        "",
        "",
        "",
        "",
        paymentCondition
      );

      const response = await requestConsumer.created(request);

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      )
        throw response;

      await requestConsumer.statusRequest({
        pedidoStatusId: status,
        pedidoId: response.data.id,
        data: new Date(),
        observacao: observationStatusReqeuest
          ? observationStatusReqeuest
          : null,
      });

      toast.success("Pedido cadastrado com sucesso!");

      request.id = response.data.id;
      setRequestSelect(request);
    } catch (e) {
      toast.error("Ops... tivemos um problema ao cadastrar o novo pedido!");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateRequest = async () => {
    if (!status || status === "select") {
      return toast.warning("Favor informar o status do pedido!");
    }

    try {
      setLoading(true);

      const request = new Requests(
        requestSelect!.id,
        number,
        value,
        new Date(emissionDate),
        new Date(deliveryDate),
        new Date(paymentPrevisionDate),
        supplierId,
        buyerId,
        invoicingId,
        shoppingProccessId,
        invoceId,
        [],
        "",
        "",
        "",
        "",
        "",
        "",
        paymentCondition
      );

      const response = await requestConsumer.updated(request);

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      )
        throw response;

      await requestConsumer.statusRequest({
        pedidoStatusId: status,
        pedidoId: requestSelect?.id,
        data: new Date(),
        observacao: observationStatusReqeuest
          ? observationStatusReqeuest
          : null,
      });

      toast.success("Pedido alterado com sucesso!");
    } catch (e) {
      toast.error("Ops... tivemos um problema ao alterar o pedido!");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRequest = async (request: iRequests) => {
    try {
      setLoading(true);

      const response = await requestConsumer.deleted(`${request?.id}`);

      if (response.status !== 200) throw response;

      toast.success("Pedido deletado com sucesso!");

      handleSelected(null);
      getRequests();
    } catch {
      toast.error("Erro ao deletar o pedido!");
    } finally {
      setLoading(false);
    }
  };

  const handleSortData = (data: { id: string; order: string }) => {
    const { id, order } = data;

    // ASC: < & >
    // DESC: > & <
    if (order === "asc") {
      const dataOrder = requests.sort(function (a: any, b: any) {
        if (a[id] < b[id]) {
          return -1;
        }
        if (a[id] > b[id]) {
          return 1;
        }
        return 0;
      });

      setRequests([...dataOrder]);
    } else {
      const dataOrder = requests.sort(function (a: any, b: any) {
        if (a[id] > b[id]) {
          return -1;
        }
        if (a[id] < b[id]) {
          return 1;
        }
        return 0;
      });

      setRequests([...dataOrder]);
    }
  };

  const setPaymentDate = ({
    id,
    paymentConditions,
  }: {
    id: string;
    paymentConditions: Array<any>;
  }) => {
    const itemPaymentCondition = paymentConditions.find((el) => el.id === id);

    if (!itemPaymentCondition) return;

    setPaymentCondition(itemPaymentCondition?.id ?? "");

    const now = new Date();
    const insertDaysInCurretPayamentCondition = new Date(
      new Date(now).setDate(now.getDate() + itemPaymentCondition.daysNumber)
    );

    setPaymentPrevisionDate(
      format(insertDaysInCurretPayamentCondition, "yyyy-MM-dd")
    );
  };

  const importInvoice = async (event: any, id: string) => {
    const file = event.target.files[0];

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("xml", file);

      const response = await invoicesConsumer.importInvoice(formData, id);

      if (response.status !== 200) throw response;

      toast.success("Importação realizada com sucesso!");
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "Erro ao importar a nota fiscal!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getRequests,
      getRequestsTotal,
      loading,
      setLoading,
      requests,
      setRequests,
      handleChangePage,
      handleChangeRowsPerPage,
      requestSelect,
      setRequestSelect,
      number,
      setNumber,
      value,
      setValue,
      emissionDate,
      setEmissionDate,
      deliveryDate,
      setDeliveryDate,
      paymentPrevisionDate,
      setPaymentPrevisionDate,
      supplierId,
      setSupplierId,
      supplier,
      setSupplier,
      buyerId,
      setBuyerId,
      buyer,
      setBuyer,
      invoicingId,
      setInvoicingId,
      shoppingProccessId,
      setShoppingProccessId,
      invoceId,
      setInvoceId,
      items,
      setItems,
      handleSelected,
      statusFilter,
      setStatusFilter,
      proccessNumberFilter,
      setProccessNumberFilter,
      descriptionFilter,
      setDescriptionFilter,
      requestNumberFilter,
      setRequestNumberFilter,
      companyFilter,
      setCompanyFilter,
      NfFilter,
      setNfFilter,
      codeItemFilter,
      setCodeItemFilter,
      descriptionItemFilter,
      setDescriptionItemFilter,
      startDateFilter,
      setStartDateFilter,
      startDateFilterNf,
      setStartDateFilterNf,
      endDateFilter,
      setEndDateFilter,
      endDateFilterNf,
      setEndDateFilterNf,
      getLastShoppingProccessPhase,
      buyers,
      suppliers,
      shoppingProccessDescription,
      setShoppingProccessDescription,
      itemsToSearch,
      setItemsToSearch,
      itemsPhaseShoppingProccess,
      handleSalveNewRequest,
      handleUpdateRequest,
      handleDeleteRequest,
      status,
      setStatus,
      paymentCondition,
      setPaymentCondition,
      observationStatusReqeuest,
      setObservationStatusRequest,
      requestSelectSearch,
      setRequestSelectSearch,
      buyerSelect,
      setBuyerSelect,
      supplierSelect,
      setSupplierSelect,
      handleSortData,
      activeTabsMenu,
      setActiveTabsMenu,
      setPage,
      setPaymentDate,
      cleanFilters,
      CSV,
      setCSV,
      CSVGrid,
      setCSVGrid,
      orderBy,
      setOrderBy,
      importInvoice,
      typeFilterDate,
      setTypeFilterDate,
      getRequestsWithoutFilter,
      open,
      handleClick,
      handleClose,
      CSVSupply,
      setCSVSupply,
    };
  }, [
    page,
    requests,
    CSV,
    requestSelect,
    number,
    value,
    emissionDate,
    deliveryDate,
    paymentPrevisionDate,
    supplierId,
    supplier,
    buyerId,
    buyer,
    invoicingId,
    shoppingProccessId,
    invoceId,
    items,
    statusFilter,
    proccessNumberFilter,
    descriptionFilter,
    requestNumberFilter,
    companyFilter,
    NfFilter,
    codeItemFilter,
    descriptionItemFilter,
    startDateFilter,
    startDateFilterNf,
    endDateFilter,
    endDateFilterNf,
    buyers,
    suppliers,
    shoppingProccessDescription,
    itemsToSearch,
    itemsPhaseShoppingProccess,
    status,
    paymentCondition,
    observationStatusReqeuest,
    requestSelectSearch,
    buyerSelect,
    supplierSelect,
    loading,
    activeTabsMenu,
    CSVGrid,
    orderBy,
    typeFilterDate,
    open,
    CSVSupply,
  ]);

  return (
    <RequestContext.Provider value={contextValue}>
      {children}
    </RequestContext.Provider>
  );
}

export default RequestContext;
