/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useInvoices from "src/hooks/useInvoices";
import useLayout from "src/hooks/useLayout";
import { iInvoicesItems } from "src/interfaces/invoicesItem";
import { iPage } from "src/interfaces/layout";
import InvoiceItems from "src/models/InvoiceItems";
import invoiceItemsConsumer from "src/services/invoiceItems";

const InvoiceItemsContext = createContext<any>({} as any);

export function InvoiceItemsProvider({ children }: { children: any }) {
  const { setOpenDialogSecondary } = useLayout();

  const {
    invoiceSelect,
    value: valueInvoice,
    setValue: setValueInvoice,
  } = useInvoices();

  const [loading, setLoading] = useState<boolean>(false);
  const [invoiceItems, setInvoiceItems] = useState<Array<iInvoicesItems>>([]);
  const [productCompanyItem, setProductCompanyItem] = useState<any>(null);
  const [invoiceItemSelect, setInvoiceItemSelect] =
    useState<iInvoicesItems | null>(null);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [quantity, setQuantity] = useState<string | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const [itemRequestInvoice, setItemRequestInvoice] = useState<any>(null);
  const [codeProduct, setCodeProduct] = useState<string | null>(null);
  const [descriptionProduct, setDescriptionProduct] = useState<string | null>(
    null
  );
  const [unitProduct, setUnitProduct] = useState<string | null>(null);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getInvoiceItems = async () => {
    setLoading(true);

    try {
      const response = await invoiceItemsConsumer.get(page, invoiceSelect?.id);

      if (response.status !== 200) throw response;

      if (page.total === 0 && response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      const data = response.data.items?.map((item: any) =>
        InvoiceItems.adapterToClass(item)
      );

      setInvoiceItems(data);
    } catch (e: any) {
      toast.error(
        e?.message ?? "Ops... identificamos um erro ao buscar os itens!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNewSalve = async () => {
    try {
      setLoading(true);

      const body = new InvoiceItems(
        "",
        `${quantity}`,
        `${value}`,
        `${invoiceSelect?.id}`,
        `${productCompanyItem?.id}`,
        productCompanyItem
      );

      const response = await invoiceItemsConsumer.created(body);

      if (response.status !== 200) throw response;

      setInvoiceItemSelect(response.data);
      setPage({ ...page, page: 0 });

      let totalValueInvoice = 0;

      if (valueInvoice) {
        totalValueInvoice = parseFloat(
          valueInvoice
            ?.replace("R$", "")
            ?.replaceAll(".", "")
            ?.replace(",", ".")
            ?.trim()
        );
      }

      const newInvoiceValueTotal =
        totalValueInvoice +
        parseFloat(value ?? "0") * parseInt(quantity ?? "0");

      setValueInvoice(
        newInvoiceValueTotal?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }) ?? "0,00"
      );

      body.id = response?.data?.id;
      setInvoiceItems([body, ...invoiceItems]);

      toast.success("Item cadastrado com sucesso!");

      if (itemRequestInvoice?.id) {
        await invoiceItemsConsumer.createdRelationWIthRequestItem({
          notaFiscalItemId: response.data.id,
          pedidoItemId: itemRequestInvoice?.id,
        });
      }

      setInvoiceItemSelect(null);
      setOpenDialogSecondary(false);
    } catch (e) {
      toast.error("Erro ao cadastrar novo item!");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const body = new InvoiceItems(
        `${invoiceItemSelect?.id}`,
        `${quantity}`,
        `${value}`,
        `${invoiceSelect?.id}`,
        `${productCompanyItem?.id}`,
        productCompanyItem
      );

      const response = await invoiceItemsConsumer.updated(body);

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      )
        throw response;

      setInvoiceItemSelect(response.data);
      setPage({ ...page, page: 0 });

      let totalValueInvoice = 0;

      if (valueInvoice) {
        totalValueInvoice = parseFloat(
          valueInvoice
            ?.replace("R$", "")
            ?.replaceAll(".", "")
            ?.replace(",", ".")
            ?.trim()
        );
      }

      const newValueInvoice =
        totalValueInvoice -
        parseFloat(invoiceItemSelect?.value ?? "0") *
          parseInt(invoiceItemSelect?.quantity ?? "0");

      const newInvoiceValueTotal =
        newValueInvoice + parseFloat(value ?? "0") * parseInt(quantity ?? "0");

      setValueInvoice(
        newInvoiceValueTotal?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }) ?? "0,00"
      );

      const newInvoices = invoiceItems.filter(
        (e) => e.id !== invoiceItemSelect?.id
      );

      setInvoiceItems([body, ...newInvoices]);

      toast.success("Item alterado com sucesso!");

      setInvoiceItemSelect(null);
      setOpenDialogSecondary(false);
    } catch (e) {
      toast.error("Erro ao alterar o item!");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (invoiceItem: iInvoicesItems) => {
    try {
      setLoading(true);

      if (invoiceItem?.notaFiscalItensPedidoItens?.length > 0) {
        const data = invoiceItem?.notaFiscalItensPedidoItens[0];
        const responseDeleteRelationWithRequestItem =
          await invoiceItemsConsumer.deletedRelationWIthRequestItem(
            `${data.id}`
          );

        if (
          responseDeleteRelationWithRequestItem.status !== 200 &&
          responseDeleteRelationWithRequestItem.status !== 201 &&
          responseDeleteRelationWithRequestItem.status !== 204
        ) {
          toast.warning(
            "Não foi possível deletar a ligação entre  os itens da nota e do pedido!"
          );
        } else {
          toast.success(
            "Ligação entre  os itens da nota e do pedido deletado com sucesso!"
          );
        }
      }

      const response = await invoiceItemsConsumer.deleted(invoiceItem?.id);

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      )
        throw response;

      setPage({ ...page, page: 0 });

      let totalValueInvoice = 0;

      if (valueInvoice) {
        totalValueInvoice = parseFloat(
          valueInvoice
            ?.replace("R$", "")
            ?.replaceAll(".", "")
            ?.replace(",", ".")
            ?.trim()
        );
      }

      const newValueInvoice =
        totalValueInvoice -
        parseFloat(invoiceItem?.value ?? "0") *
          parseInt(invoiceItem?.quantity ?? "0");

      setValueInvoice(
        newValueInvoice?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }) ?? "0,00"
      );

      const newInvoices = invoiceItems.filter((e) => e.id !== invoiceItem?.id);

      setInvoiceItems([...newInvoices]);

      toast.success("Item deletado com sucesso!");
    } catch {
      toast.error("Erro ao deletar o item!");
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = async (item: iInvoicesItems | null) => {
    setInvoiceItemSelect(item ?? null);

    if (item?.notaFiscalItensPedidoItens?.length > 0) {
      const data = item?.notaFiscalItensPedidoItens[0];
      const bodyItem = {
        id: data.pedidoItem?.id,
        code: data.pedidoItem?.produtoEmpresa?.codigoProdutoEmpresa,
        label: data.pedidoItem?.produtoEmpresa?.descricao,
        ...data,
      };
      setItemRequestInvoice(bodyItem ?? null);
    } else {
      setItemRequestInvoice(null);
    }

    if (item?.productCompany) {
      setProductCompanyItem({
        id: item?.productCompany?.id,
        code: item?.productCompany?.codigoProdutoEmpresa,
        label: item?.productCompany?.descricao,
        unidade: item?.productCompany?.unidade,
      });
      setCodeProduct(item?.productCompany?.codigoProdutoEmpresa ?? "");
      setDescriptionProduct(item?.productCompany.descricao ?? "");
      setUnitProduct(item?.productCompany?.unidade ?? "");
    } else {
      setProductCompanyItem(null);
      setCodeProduct("");
      setDescriptionProduct("");
      setUnitProduct("");
    }

    setQuantity(item?.quantity ?? "");
    setValue(item?.value ?? "");
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getInvoiceItems,
      loading,
      setLoading,
      invoiceItems,
      setInvoiceItems,
      handleChangePage,
      handleChangeRowsPerPage,
      quantity,
      setQuantity,
      value,
      setValue,
      invoiceItemSelect,
      setInvoiceItemSelect,
      handleNewSalve,
      handleUpdate,
      handleDelete,
      handleSelect,
      itemRequestInvoice,
      setItemRequestInvoice,
      codeProduct,
      setCodeProduct,
      descriptionProduct,
      setDescriptionProduct,
      unitProduct,
      setUnitProduct,
      productCompanyItem,
      setProductCompanyItem,
    };
  }, [
    page,
    loading,
    invoiceItems,
    quantity,
    value,
    invoiceItemSelect,
    itemRequestInvoice,
    codeProduct,
    descriptionProduct,
    unitProduct,
    productCompanyItem,
    invoiceSelect,
  ]);

  return (
    <InvoiceItemsContext.Provider value={contextValue}>
      {children}
    </InvoiceItemsContext.Provider>
  );
}

export default InvoiceItemsContext;
