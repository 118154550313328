/* eslint-disable react-hooks/exhaustive-deps */
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import {
  ButtonInclude,
  ButtonSearch,
  ButtonSendMail,
} from "src/components/ButtonsCommons";
import Layout from "src/components/Layout";
import useLayout from "src/hooks/useLayout";
import { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Card,
  Checkbox,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import useInvoicing from "src/hooks/useInvoicing";
import ReactInputMask from "react-input-mask";
import { MaskStyle } from "src/styles/globalStyle";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import { iInvoicing } from "src/interfaces/invoicing";
import { addHours, format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useTable from "src/hooks/useTable";
import DialogComponent from "src/components/Dialog";
import SendMail from "./SendMail";
import IconRed from "src/assets/images/Icon-red.svg";
import LogMail from "./LogMail";

const Invoicing: React.FC = () => {
  const { disableButtons, setDisableButtons, width } = useLayout();
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { setFieldOrder } = useTable();

  const {
    loading,
    getInvoicings,
    statusAvaliable,
    invoicings,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    invoicingSelect,
    handleSelect,
    orderBy,
    setOrderBy,
    clientSearch,
    setClientSearch,
    nfSearch,
    setNfSearch,
    dateFromSearch,
    setDateFromSearch,
    dateToSearch,
    setDateToSearch,
    statusSearch,
    setStatusSearch,
    setDateFrom,
    setDateTo,
    setStatus,
    setSupplyer,
    setInvoicesSupplyer,
    setDisableSupplier,
    setAdamentoStatus,
    setPaymentDate,
    setInvoicingDate,
    setPaymentCondition,
    setDataInicialPrevPgto,
    setDataFinalPrevPgto,
    getInvoicingsStatus,
    invoicesChecked,
    allChecked,
    handleSelectAll,
    setInvoicesChecked,
    totalGeneralSelected,
    setTotalGeneralSelected,
    openDialogSendMail,
    setOpenDialogSendMail,
    getLogSendMail,
    setLogsEmails,
    openDialogLogMail,
    setOpenDialogLogMail,
  } = useInvoicing();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["invoicing"],
    },
  ];

  const header = [
    {
      label: translations[param]["date"],
    },
    {
      label: translations[param]["supplier"],
    },
    {
      label: translations[param]["value"],
    },
    {
      label: translations[param]["number_nf"],
    },
    {
      label: translations[param]["date_nf"],
    },
    {
      label: `${translations[param]["value"]} ${translations[param]["paid_out"]}`,
    },
    {
      label: `${translations[param]["date"]} ${translations[param]["payment"]}.`,
    },
    {
      label: translations[param]["status"],
    },
    {
      label: "Log envio",
    },
    {
      hiddenSortedList: true,
      label: (
        <Checkbox
          color="primary"
          checked={allChecked}
          onChange={handleSelectAll}
        />
      ),
    },
  ];

  useEffect(() => {
    setDisableButtons(true);
    handleSelect(null);
    setFieldOrder({
      id: "",
      label: translations[param]["date"],
      isOrder: false,
      order: 1,
    });
    getInvoicingsStatus();
    setInvoicesChecked([]);
    setLogsEmails([]);
    setOpenDialogLogMail(false);
  }, []);

  useEffect(() => {
    getInvoicings();
  }, [orderBy, page.page]);

  useEffect(() => {
    setTotalGeneralSelected(
      invoicesChecked
        ?.map((item: any) => item.invoiceValue ?? 0)
        ?.reduce(
          (accumulator: any, curretValue: any) => (accumulator += curretValue),
          0
        )
        ?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }) ?? "0,00"
    );
  }, [invoicesChecked]);

  const cleanFIlters = () => {
    setClientSearch("");
    setStatusSearch("select");
    setNfSearch("");
    setDateFromSearch("");
    setDateToSearch("");
  };

  const buttons = [
    <ButtonSendMail
      label=" Enviar E-mail Pré-Faturamento"
      disabled={invoicesChecked.length === 0}
      marginRight="0px"
      onClick={() => setOpenDialogSendMail(true)}
    />,
    <ButtonInclude
      label="Gerar Pré-Faturamento em Lote"
      disabled={!disableButtons}
      marginRight="0px"
      onClick={() => {
        setPaymentCondition("select");
        setDataInicialPrevPgto("");
        setDataFinalPrevPgto("");
        setInvoicingDate("");
        router(`/${lang}/invocing/register/lote`);
      }}
    />,
    <ButtonInclude
      label={translations[param]["include"]}
      disabled={!disableButtons}
      onClick={() => {
        setDateFrom("");
        setDateTo("");
        setStatus("select");
        setSupplyer(null);
        setInvoicesSupplyer([]);
        setDisableSupplier(true);
        setAdamentoStatus([]);
        setPaymentDate("");
        setInvoicingDate("");
        setPaymentCondition("select");
        setDataInicialPrevPgto("");
        setDataFinalPrevPgto("");
        router(`/${lang}/invocing/register`);
      }}
    />,
  ];

  if (loading) {
    return (
      <Layout buttons={buttons} breadcrumbs={breadcrumb}>
        <CircularLoading />
      </Layout>
    );
  }

  return (
    <Layout buttons={buttons} breadcrumbs={breadcrumb} callMenu={cleanFIlters}>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          spacing={0}
          columnSpacing={1}
        >
          <Grid xs={12} sm={12} md={12} lg={6}>
            <FormLabel>{translations[param]["client"]}:</FormLabel>
            <FormControl fullWidth>
              <MaskStyle>
                <ReactInputMask
                  mask="99.999.999/9999-99"
                  name="cnpj"
                  placeholder={`CNPJ ${translations[param]["client"]}`}
                  value={clientSearch}
                  onChange={(e) => setClientSearch(e.target.value)}
                />
              </MaskStyle>
            </FormControl>
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={6}>
            <FormLabel>{translations[param]["status"]}:</FormLabel>
            <Select
              labelId="show_select_search_company"
              id="show_select_search_company"
              value={statusSearch}
              fullWidth
              size="small"
              onChange={(event: SelectChangeEvent) =>
                setStatusSearch(event.target.value as string)
              }
            >
              <MenuItem value="select" disabled>
                {width <= 1400
                  ? `${translations[param]["status"]} - ${translations[param]["select"]}`
                  : translations[param]["select"]}
              </MenuItem>
              <MenuItem value="">...</MenuItem>
              {statusAvaliable?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.descricao}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={6}>
            <FormLabel>NF:</FormLabel>
            <TextField
              size="small"
              fullWidth
              id="nf"
              name="nf"
              label={translations[param]["number_nf"]}
              variant="outlined"
              value={nfSearch}
              onChange={(e) => setNfSearch(e.target.value)}
            />
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={6} display="flex">
            <Grid sx={{ width: "100%" }}>
              <FormLabel>{translations[param]["from"]}:</FormLabel>
              <FormControl fullWidth>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    value={dateFromSearch}
                    onChange={(e) => setDateFromSearch(e.target.value)}
                    min="1970-01-01"
                  />
                </MaskStyle>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <FormLabel>{translations[param]["to"]}:</FormLabel>
              <FormControl fullWidth>
                <MaskStyle>
                  <input
                    type="date"
                    name="end_date"
                    placeholder="dd/mm/yyyy"
                    value={dateToSearch}
                    onChange={(e) => setDateToSearch(e.target.value)}
                    min="1970-01-01"
                  />
                </MaskStyle>
              </FormControl>
            </Grid>
          </Grid>

          {/* Search Button */}
          <Grid
            xs={12}
            sx={{ marginTop: 1 }}
            display="flex"
            justifyContent="space-between"
          >
            <Grid xs={12} sm={12} md={12} lg={4}>
              <FormLabel>Total Selecionados:</FormLabel>
              <TextField
                size="small"
                fullWidth
                label="Total"
                variant="outlined"
                value={totalGeneralSelected}
                disabled
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={4}>
              <br />
              <ButtonSearch
                label={translations[param]["search"]}
                onClick={() => getInvoicings()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ padding: 2, width: "100%", marginTop: 5 }}>
        {invoicings.length === 0 ? (
          <Empty />
        ) : (
          <TableComponent
            header={header}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            funtionToOrder={(item) =>
              item?.orderBackend &&
              setOrderBy({
                number: item?.orderBackend,
                order: item?.order === "asc" ? true : false,
              })
            }
          >
            {invoicings.map((data: iInvoicing) => (
              <TableRow
                hover
                key={data.id}
                selected={data.id === invoicingSelect?.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.dataFaturamento
                    ? format(
                        addHours(new Date(data.dataFaturamento), 3),
                        "dd/MM/yyyy"
                      )
                    : null}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data?.companyName}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.invoiceValue?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }) ?? "R$ 0,00"}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.numberNF}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.emissionDateNF
                    ? format(new Date(data.emissionDateNF), "dd/MM/yyyy")
                    : null}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {parseFloat(data.paymentValue ?? "0,00")?.toLocaleString(
                    "pt-br",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.paymentDate
                    ? format(new Date(data.paymentDate), "dd/MM/yyyy")
                    : null}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.status}
                </TableCell>
                <TableCell>
                  <img
                    src={IconRed}
                    alt="icon_excel"
                    width={20}
                    style={{ marginLeft: "1%", cursor: "pointer" }}
                    onClick={async () => {
                      await getLogSendMail(data.id);
                      setOpenDialogLogMail(true);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    color="primary"
                    checked={invoicesChecked.some(
                      (invoice: any) => invoice.id === data.id
                    )}
                    size="small"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setInvoicesChecked((prev: any) => [...prev, data]);
                      } else {
                        setInvoicesChecked((prev: any) =>
                          prev.filter((invoice: any) => invoice.id !== data.id)
                        );
                      }
                    }}
                    onClick={() => {
                      handleSelect(data);
                      setDisableButtons(!invoicesChecked.includes(data.id));
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableComponent>
        )}
      </Card>

      <DialogComponent open={openDialogSendMail} handleClose={() => {}}>
        <SendMail />
      </DialogComponent>

      <DialogComponent open={openDialogLogMail} handleClose={() => {}}>
        <LogMail />
      </DialogComponent>
    </Layout>
  );
};

export default Invoicing;
